import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { RoundedButton, Input, Label, Text } from './ThemedComponents';
import StyleGuide from './StyleGuide';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ color }) => color || 'inherit'};
  cursor: pointer;
  font-size: 1em;

  @media only screen and (max-width: 414px) {
    font-size: 0.9em;
  }
`;

/**
 * Custom Radio Input Box
 */
const InputRadio = ({
  margin,
  text,
  flex,
  checked,
  onChange,
  bgColor,
  inputColor,
  padding,
  error = false,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  return (
    <RoundedButton
      padding={padding || '0px 5px'}
      flex={flex}
      margin={margin || '6px 5px 6px 0px'}
      justifyContent="flex-start"
      backgroundColor={error ? StyleGuide.colors.error : bgColor || theme.input}
      onClick={() => onChange()}
    >
      <Container
        pointer
        color={error ? StyleGuide.colors.fontOnError : inputColor || theme.inputFont}
      >
        <Input
          margin="0px 5px"
          type="radio"
          checked={checked}
          color={error ? StyleGuide.colors.fontOnError : inputColor || theme.inputFont}
          {...props}
        />
        <Text color="inherit" margin="5px 0px" style={{ fontSize: 'inherit' }}>
          {text}
        </Text>
      </Container>
    </RoundedButton>
  );
};

export default InputRadio;
