import axios from 'axios';
import { shuffle, fromPairs, toPairs, mapValues, mapKeys, isEmpty, isArray, pickBy, head } from 'lodash';
import Router from 'next/router';
import Config from '../config';

const isFile = (toTest) => {
  if (typeof toTest !== 'object' || !toTest) return false;
  const { path, name, type, ...rest } = toTest;
  return !!path && !!name && isEmpty(rest);
};

const isFileArray = (toTest) => isArray(toTest) && toTest.every(isFile);

const webAppClient = axios.create({
  baseURL: Config.api.url,
  headers: {
    'x-client-name': 'webversion',
    //'x-app-name': 'belovd'
  },
  transformRequest: [
    (data) => {
      if (!data) {
        return null;
      }
      if (Object.values(data).some((value) => isFile(value) || isFileArray(value))) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          if (isArray(value)) {
            value.forEach((item) => formData.append(`${key}[]`, item));
          } else if (typeof value !== 'undefined') {
            formData.append(key, value);
          }
        });
        return formData;
      }
      return Object.keys(data)
        .filter((key) => typeof data[key] !== 'undefined')
        .reduce((obj, key) => ({ ...obj, [key]: data[key] }), {});
    },
    ...axios.defaults.transformRequest,
  ],
});

webAppClient.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RES', response);
    }
    return response;
  },
  (error) => {
    console.warn('responseError', error);
    if (error.response) {
      // if (error.response.status === 900) {
      //   handleMaintenance();
      // }
    }
    const errorValue = (error.response && error.response.data) || error;
    return Promise.reject(errorValue);
  }
);

const handleMaintenance = () => {
  console.log('Maintenance-Modus');
  Router.push({
    pathname: '/maintenance',
    query: { maintenance: true },
  });
};

export const SetToken = (token) => {
  webAppClient.defaults.headers.Authorization = `Bearer ${token}`;
};

export const RemoveToken = () => {
  webAppClient.defaults.headers.Authorization = null;
};

export const GetProfile = (id) =>
  webAppClient
    .get(`v1/users/${id}`)
    .then(
      ({
        status,
        data: {
          items: {
            user: {
              conversationHash,
              age,
              firstname: name,
              gender,
              picture,
              friendRequestStatus,
              hasLiked,
              statement,
              isSupportBot,
              profile: {
                zodiac,
                bodyDecoration,
                searchFor,
                city,
                eyes,
                hairColor: hair,
                height,
                relationship,
                smoking,
                sentIcebreaker,
                friendshipCounter,
              },
            },
          },
        },
      }) =>
        status === 200
          ? {
              age,
              name,
              gender,
              id,
              hasLiked,
              conversationHash,
              friendRequestStatus,
              image: { uri: picture },
              zodiac,
              bodyDecoration,
              searchFor,
              city,
              eyes,
              hair,
              height,
              relationship,
              smoking,
              sentIcebreaker: sentIcebreaker > 0,
              friendshipCounter,
              status: statement,
              isBot: isSupportBot,
            }
          : null
    );

export const UpdateProfile = ({
  hair: hairColor,
  height,
  smoking,
  eyes,
  relationship,
  bodyDecoration,
  searchFor,
}) =>
  webAppClient.put(
    'v1/userprofiles',
    pickBy(
      {
        hairColor,
        height,
        smoking,
        eyes,
        relationship,
        searchFor,
        bodyDecoration,
      },
      (a) => !!a
    )
  );

export const Logout = () => webAppClient.get('auth/logout');

export const GetConversations = (favorites = false, nextCursorr = null, perPage = 15) =>
  webAppClient
    .get('v1/conversations', {
      params: {
        next_cursor: nextCursorr,
        per_page: perPage,
        favorites: favorites ? 1 : 0,
        dimension: 640,
      },
    })
    .then(
      ({
        data: {
          items,
          meta: {
            pagination: { has_more_pages: moreRecords, next_cursor: nextCursor },
          },
        },
      }) => ({
        conversations: items.map(
          ({
            hash,
            isNewMatch,
            canAnswer,
            users,
            lastMessage,
            createdAt,
            isBot,
            sentIcebreaker,
          }) => ({
            id: hash,
            isNewMatch,
            canAnswer,
            sentIcebreaker,
            createdAt,
            isBot,
            isFavorite: favorites,
            lastMessage:
              lastMessage &&
              mapKeys(
                lastMessage,
                (val, key) =>
                  ({
                    userGuid: 'userId',
                    MessageType: 'type',
                    lastDate: 'createdAt',
                  }[key] || key)
              ),
            users: users.map(({ firstname, age, guid, picture, profile: { city } }) => ({
              name: firstname,
              age,
              city,
              id: guid,
              image: { uri: picture },
            })),
          })
        ),
        moreRecords,
        nextCursor,
      })
    );

export const GetMatches = (nextCursor = null, perPage = 12) =>
  webAppClient
    .get('v3/voting/likes', {
      params: { next_cursor: nextCursor, per_page: perPage, only: 'match' },
    })
    .then(
      ({
        data: {
          items,
          meta: {
            pagination: { has_more_pages: moreRecords, next_cursor: nextCursorr },
          },
        },
      }) => ({
        matches: items.map(
          ({
            userGuid,
            age,
            city,
            picture,
            created_at,
            name,
            liker: { friendRequestStatus, friendshipCounter },
          }) => ({
            id: userGuid,
            city,
            age,
            name,
            createdAt: created_at,
            friendrequeststatus: friendRequestStatus,
            image: { uri: picture },
            friendshipCounter: friendshipCounter || 0,
          })
        ),
        moreRecords,
        nextCursor: nextCursorr,
      })
    );

export const SendChatmessage = ({ text, conversationId, recipients, type }) =>
  webAppClient
    .post('v1/messages/store', {
      message: text,
      hashId: conversationId,
      recipients,
      type,
    })
    .then(
      ({
        status,
        data: {
          items: { id, userGuid: userId, text, type, createdAt, hashId, message: preview },
        },
      }) =>
        status === 200
          ? {
              id,
              userId,
              text,
              type,
              createdAt,
              preview,
              conversationId: conversationId || hashId,
            }
          : {}
    );

export const GetConversationId = (userIds) =>
  webAppClient.post('v1/conversations/gen/hash', { participants: userIds }).then(
    ({
      data: {
        items: { conversationHash },
      },
    }) => conversationHash
  );

export const getConversation = (id) =>
  webAppClient.get('v1/conversations', { params: { hash_id: id } }).then(({ data: { items } }) =>
    head(
      items.map(({ hash, isNewMatch, canAnswer, users, createdAt, sentIcebreaker, isBot }) => ({
        id: hash,
        isNewMatch,
        canAnswer,
        sentIcebreaker,
        createdAt,
        isBot,
        users: users.map(({ firstname, age, guid, picture, profile: { city } }) => ({
          name: firstname,
          age,
          city,
          id: guid,
          image: { uri: picture },
        })),
      }))
    )
  );

export const GetMessages = (conversationId, limit, nextCursor = null, sort = 'desc') =>
  webAppClient
    .get(`v1/messages/${conversationId}`, {
      params: { next_cursor: nextCursor, sort, per_page: limit },
    })
    .then(
      ({
        status,
        data: {
          items,
          meta: {
            pagination: { has_more_pages: moreMessages, next_cursor: nextCursorr },
          },
        },
      }) =>
        status === 200
          ? {
              moreRecords: moreMessages,
              nextCursor: nextCursorr,
              messages: items.map(({ id, userGuid: userId, text, type, createdAt, message }) => ({
                id,
                userId,
                text,
                type,
                createdAt,
                conversationId,
                message,
              })),
            }
          : { moreRecords: true, nextCursor: null, messages: [] }
    );

export const GetUnreadMessages = () =>
  webAppClient
    .get('v1/messages/count')
    .then(({ status, data: { items: { conversations, countNewConversations } } }) =>
      status === 200
        ? {
            unread: conversations.map(({ hashId, count }) => ({
              id: hashId,
              count,
            })),
            matches: countNewConversations,
          }
        : []
    );

export const DeleteConversation = (conversationId) =>
  webAppClient.delete(`v1/conversations/${conversationId}`);

const transformUserResponse = ({
  guid: id,
  firstname: name,
  age,
  gender,
  picture: image,
  hasDownloadedApp,
  messages: { pictures },
  status,
  birthday,
  possibleCity,
  email,
  statement,
  allowPushNewMessage,
  allowPushNewMatch,
  allowPushNewLike,
  allowPushNewVisitor,
  allowPushNewFriendsrequest,
  countryCode,
  profile: {
    city,
    zodiac,
    bodyDecoration,
    searchFor,
    eyes,
    height,
    hairColor: hair,
    relationship,
    smoking,
    searchedAgeFrom,
    searchedAgeTo,
    searchedDistance,
    searchedGender,
  },
}) => ({
  profile: {
    id,
    name,
    age,
    gender,
    countryCode,
    image: { uri: image },
    city,
    zodiac,
    searchFor,
    bodyDecoration,
    eyes,
    hair,
    height,
    relationship,
    smoking,
    email,
    birthday,
    possibleCity,
    statement,
  },
  status,
  notice: pictures,
  id,
  hasDownloadedApp,
  settings: {
    searchedAgeFrom,
    searchedAgeTo,
    searchedDistance,
    searchedGender,
  },
  permissions: {
    allowPushNewMessage: allowPushNewMessage !== 0,
    allowPushNewMatch: allowPushNewMatch !== 0,
    allowPushNewLike: allowPushNewLike !== 0,
    allowPushNewVisitor: allowPushNewVisitor !== 0,
    allowPushNewFriendsrequest: allowPushNewFriendsrequest !== 0,
  },
});

export const GetOwnProfile = () =>
  webAppClient.get('v3/user').then(({ data: { items: user } }) => transformUserResponse(user));

export const Login = ({ email, password, userId, origin, token, deviceId, platform = 'web' }) =>
  webAppClient
    .post(
      `v2/auth/${origin}`,
      pickBy(
        { identifier: userId || email, password, token, deviceId, platform },
        (item) => item !== undefined
      )
    )
    .then(({ data: { statusCode, items: { accessToken: bearerToken, user } } }) => ({
      statusCode,
      accessToken: bearerToken,
      ...transformUserResponse(user, {}),
    }));

export const Register = ({
  origin,
  userId,
  token,
  email,
  password,
  platform = 'web',
  deviceId,
  gender,
  name,
  birthday,
  image,
  lat,
  lng,
  city,
  country,
  locationId,
  leadPage,
}) =>
  webAppClient
    .post(
      `v2/register/${origin}`,
      pickBy(
        {
          identifier: userId || email,
          token,
          firstname: name,
          password,
          platform,
          deviceId,
          gender,
          birthdate: birthday,
          geo: { lat, lng, city, country },
          locationId,
          email,
          media: {
            guid: image.id,
            mimeType: image.mimeType,
            filename: image.fileName,
            mediaType: 'image',
          },
          leadPage,
        },
        (value) => !!value
      )
    )
    .then(({ data: { statusCode, items: { accessToken: bearerToken, user } } }) => ({
      statusCode,
      accessToken: bearerToken,
      ...transformUserResponse(user, image),
    }));

export const PreCheckLogin = (identifier) =>
  webAppClient.get('/v2/account/precheck', { params: { identifier } }).then(
    ({
      data: {
        items: { userExisting },
      },
    }) => userExisting
  );

export const GetSwipes = () =>
  webAppClient.get('v1/voting').then(({ data: { items, swipesLeftOver } }) => ({
    swipes: items.map(({ guid: id, firstname: name, age, picture, profile: { city } }) => ({
      id,
      name,
      age,
      city,
      image: { uri: picture },
    })),
    count: swipesLeftOver,
  }));

export const Like = (id) =>
  webAppClient.get(`v1/voting/like/${id}`).then(
    ({
      status,
      data: {
        items: { match, conversation },
      },
    }) => {
      if (status !== 200 || !match) {
        return null;
      }
      const { hash, isNewMatch, canAnswer, users, createdAt } = conversation;
      return {
        id: hash,
        isNewMatch,
        canAnswer,
        createdAt,
        users: users.map(({ guid, firstname, age, picture, profile: { city } }) => ({
          id: guid,
          name: firstname,
          age,
          city,
          image: { uri: picture },
        })),
      };
    }
  );

export const Dislike = (id) => webAppClient.get(`v1/voting/pass/${id}`);

export const GetVisitors = (nextCursor = null, perPage = 18) =>
  webAppClient
    .get('v1/visits/list', { params: { next_cursor: nextCursor, per_page: perPage, sort: 'desc' } })
    .then(
      ({
        data: {
          items,
          meta: {
            pagination: { has_more_pages: moreItems, next_cursor },
          },
        },
      }) => ({
        visitors: items.map(
          ({
            guid: id,
            unlocked,
            userGuid: userId,
            name,
            age,
            picture,
            city,
            created_at: createdAt,
          }) => ({
            id,
            userId,
            unlocked,
            name,
            age,
            city,
            createdAt,
            image: { uri: picture },
          })
        ),
        moreItems,
        nextCursor: next_cursor,
      })
    );

export const UnlockVisitor = (visitorId) =>
  webAppClient
    .get(`v1/visits/unlock/${visitorId}`)
    .then(({ status, data: { items: { userGuid: userId, name, age, picture } } }) =>
      status === 200
        ? {
            userId,
            name,
            age,
            image: { uri: picture },
          }
        : null
    );

export const GetPriceList = () =>
  webAppClient
    .get('v1/services/list')
    .then(({ status, data: { items } }) =>
      status === 200
        ? items.reduce((object, { key, coinCosts }) => ({ ...object, [key]: coinCosts }), {})
        : null
    );

export const GetProducts = () =>
  webAppClient
    .get('v1/products', { params: { os: 'web', type: 'service' } })
    .then(({ status, data: { items: { products } } }) =>
      status === 200
        ? products.map(
            ({ externalId: id, count, focus, extracoins: extraCoins, price, currency }) => ({
              id,
              count,
              focus,
              extraCoins,
              price,
              currency,
            })
          )
        : null
    )
    .then(products => {
      //calc saving
      let factors = products.map(p => ({id: p.id, val: (p.count+(p.extraCoins||0)), price: p.price, factor: (p.count+(p.extraCoins||0))/p.price}))
      let smallestFactor = factors.reduce((a, b) => Math.min(a.factor, b.factor) == a.factor ? a : b).factor;
      factors = fromPairs((factors.map((item, i) => ([item.id, Math.ceil((100/smallestFactor*item.factor)-100)]))));

      products.forEach((item, i) => {
        item.saving = factors[item.id];
        if(item.saving == 0) delete item.saving;
      });

      return products;
    })

export const GetPresents = (id, timestamp) =>
  webAppClient
    .get(`v2/users/gifts/${id}`, { params: { updated_at: timestamp } })
    .then(({ data: { items: { gifts, users } } }) => ({
      presents: gifts.map(({ guid: id, count, userGuid: userId, image }) => ({
        id,
        count,
        userId,
        image: { uri: image },
      })),
      users: users.map(
        ({ guid: id, firstname: name, picture: image, lastGiftAt: lastGiftSent, age, city }) => ({
          id,
          name,
          image: { uri: image },
          lastGiftSent,
          age,
          city,
        })
      ),
    }));

export const GetSentPresents = (timestamp) =>
  webAppClient
    .get('v2/users/gifts/sent', { params: { updated_at: timestamp } })
    .then(({ data: { items: { gifts, users } } }) => ({
      presents: gifts.map(({ guid: id, count, userGuid: userId, image }) => ({
        id,
        count,
        userId,
        image: { uri: image },
      })),
      users: users.map(
        ({ guid: id, firstname: name, picture: image, lastGiftAt: lastGiftSent, age, city }) => ({
          id,
          name,
          image: { uri: image },
          lastGiftSent,
          age,
          city,
        })
      ),
    }));

export const GetOwnSettings = () =>
  webAppClient
    .get('v1/user')
    .then(
      ({
        status,
        data: {
          items: {
            profile: { searchedAgeFrom, searchedAgeTo, searchedDistance, searchedGender },
          },
        },
      }) =>
        status === 200
          ? {
              searchedAgeFrom,
              searchedAgeTo,
              searchedDistance,
              searchedGender,
            }
          : null
    );

export const UpdateSettings = ({
  searchedAgeFrom,
  searchedAgeTo,
  searchedDistance,
  searchedGender,
}) =>
  webAppClient.put(
    'v1/userprofiles',
    pickBy(
      {
        searchedAgeFrom,
        searchedAgeTo,
        searchedDistance,
        searchedGender,
      },
      (a) => !!a
    )
  );

export const UpdateUser = ({ lat, lng, fcmToken, appsflyer_id, clickId }) =>
  webAppClient.put(
    'v1/users',
    pickBy({ lat, lng, fcmToken, appsflyer_id, ad_id: clickId }, (a) => !!a)
  );

export const ChangeUserLocation = (locationId) =>
  webAppClient.put('/v1/users', { location_id: locationId });

export const GetGiftList = () =>
  webAppClient
    .get('v1/services/gifts', { params: { childs: 1 } })
    .then(({ status, data: { items } }) =>
      status === 200
        ? items.map(
            ({
              key,
              coinCosts,
              media: {
                filename,
                filepath,
                children,
                meta: { width, height },
              },
            }) => ({
              key,
              price: coinCosts,
              image:
                children && children.length > 0
                  ? [
                      { uri: filepath + filename, height, width },
                      ...children.map(
                        ({
                          filename: childName,
                          filepath: childPath,
                          meta: { height: childHeight, width: childWidth },
                        }) => ({
                          uri: childPath + childName,
                          height: childHeight,
                          width: childWidth,
                        })
                      ),
                    ]
                  : { uri: filepath + filename },
            })
          )
        : null
    );

export const SendGift = (id, name) =>
  webAppClient
    .post(`v1/users/${id}/gift/send`, { gift: name.replace(/^gift_/, '') })
    .then(({ status, data: { items: { id, userGuid: userId, text, type, createdAt, hashId } } }) =>
      status === 200
        ? {
            id,
            userId,
            text,
            type,
            createdAt,
            conversationId: hashId,
          }
        : {}
    );

export const GetGalleryImages = (id, page, perPage, profile = false) =>
  webAppClient
    .get(`v2/media/list/${id}`, {
      params: {
        next_cursor: null,
        per_page: perPage,
        types: 'picture',
        includes: 'children',
        profile: profile ? 1 : 0,
      },
    })
    .then(({ status, data: { items, meta: { pagination: { has_more_pages: moreRecords } } } }) =>
      status === 200
        ? {
            images: items.map(
              ({
                guid: imageId,
                unlockedForUser,
                costUnlock,
                isBillable,
                visible,
                children,
                is_profile: isProfile,
              }) => ({
                id: imageId,
                isProfile,
                galleryId: id,
                isBillable,
                onlyForFriends: visible === 2,
                locked: unlockedForUser === false,
                price: costUnlock,
                image: children
                  .map(({ filepath: path, height: imageHeight, width: imageWidth }) => ({
                    uri: path,
                    height: imageHeight,
                    width: imageWidth,
                  }))
                  .find((item) => item?.width === 1440),
              })
            ),
            moreRecords,
          }
        : null
    );

export const getFriends = () =>
  webAppClient.get('v1/friends/all').then(({ status, data: { items } }) =>
    status === 200
      ? items.map(
          ({
            updated_at,
            isLiked,
            isMatch,
            userData: { age, city, firstname: name, guid: id, profilePicture: uri },
          }) => ({
            id,
            age,
            city,
            name,
            image: { uri },
            isLiked,
            isMatch,
            updatedAt: updated_at,
          })
        )
      : []
  );

export const getFriendRequests = () =>
  webAppClient.get('v1/friends/pending').then(({ status, data: { items } }) =>
    status === 200
      ? items.map(
          ({
            ownRequest,
            created_at,
            userData: { age, city, firstname: name, guid: id, profilePicture: uri },
            friendshipCounter,
          }) => ({
            id,
            ownRequest,
            friendshipCounter,
            age,
            city,
            name,
            createdAt: created_at,
            image: { uri },
          })
        )
      : []
  );

export const removeFriend = (id) => webAppClient.get(`v1/friends/remove/${id}`);

export const acceptFriendRequest = (id) => webAppClient.get(`v1/friends/accept/${id}`);

export const denyFriendRequest = (id) => webAppClient.get(`v1/friends/deny/${id}`);

export const sendFriendRequest = (id) =>
  webAppClient
    .get(`v1/friends/add/${id}`)
    .then(({ data: { items: { userData: { age, firstname: name, city, profilePicture } } } }) => ({
      age,
      id,
      name,
      city,
      image: { uri: profilePicture },
    }));

export const report = (id) => webAppClient.post('v1/report/store', { reported_guid: id });

export const searchUser = (
  { gender, age, radius, name, searchedGender, searchedAgeFrom, searchedAgeTo, searchedDistance },
  perPage = 24,
  nextCursor = 1,
  shuffleResults = false
) =>
  webAppClient
    .post(
      'v3/search/users',
      pickBy(
        {
          firstName: name,
          gender: gender || searchedGender,
          distance: radius || searchedDistance,
          age_from: age[0] || searchedAgeFrom,
          age_to: age[1] || searchedAgeTo,
        },
        (item) => item !== null || !isEmpty(item)
      ),
      { params: { nextPage: nextCursor, perPage } }
    )
    .then(({ data: { items, meta: { pagination: { moreResults } } } }) => ({
      results: items.map(
        ({
          guid: id,
          firstname,
          age,
          city,
          picture,
          hasLiked,
          friendRequestStatus,
          friendshipCounter,
        }) => ({
          id,
          name: firstname,
          age,
          city,
          isLiked: hasLiked,
          friendRequestStatus,
          friendshipCounter,
          image: { uri: picture },
        })
      ),
      moreRecords: moreResults,
    })).then(data => {
      data.results = shuffleResults ? shuffle(data.results) : data.results;
      return data;
    })

export const unlockImage = (id) =>
  webAppClient
    .get(`v1/media/unlock/${id}`)
    .then(
      ({
        status,
        data: {
          items: {
            filepath,
            filename,
            children,
            isBillable,
            unlockedForUser,
            meta: { width, height },
          },
        },
      }) =>
        status === 200
          ? {
              isBillable,
              locked: unlockedForUser === false,
              image: children
                ? [
                    { uri: filepath + filename, height, width },
                    ...children.map(
                      ({
                        filepath: path,
                        filename: name,
                        meta: { height: imageHeight, width: imageWidth },
                      }) => ({
                        uri: path + name,
                        height: imageHeight,
                        width: imageWidth,
                      })
                    ),
                  ]
                : { uri: filepath + filename },
            }
          : {}
    );

export const uploadImages = (files, cost, visible, isProfilePic = 0) =>
  webAppClient
    .post('v1/media/create', { filelist: files, cost, visible, isProfile: isProfilePic })
    .then(({ status, data: { items } }) =>
      status === 200
        ? items.map(
            ({
              guid: imageId,
              unlockedForUser,
              costUnlock,
              isBillable,
              children,
              is_profile: isProfile,
              meta: { height, width },
              onlyForFriends,
              filepath,
              filename,
            }) => ({
              id: imageId,
              isProfile,
              isBillable,
              onlyForFriends,
              locked: unlockedForUser === false,
              price: costUnlock,
              image: children
                ? [
                    { uri: filepath + filename, height, width },
                    ...children.map(
                      ({
                        filepath: path,
                        filename: name,
                        meta: { height: imageHeight, width: imageWidth },
                      }) => ({
                        uri: path + name,
                        height: imageHeight,
                        width: imageWidth,
                      })
                    ),
                  ]
                : { uri: filepath + filename },
            })
          )
        : []
    );

export const uploadImagesV2 = ({
  filename,
  mimeType,
  cost,
  context,
  userGuid,
  guid,
  mediaType = 'image',
  isProfilePic = 0,
}) =>
  webAppClient
    .post('v2/media/create', {
      filename,
      mimeType,
      cost,
      context,
      userGuid,
      mediaType,
      isProfile: isProfilePic,
      guid,
    })
    .then(({ status, data: { items } }) =>
      status === 200
        ? [items].map(
            ({
              guid: imageId,
              unlockedForUser,
              costs,
              isBillable,
              isProfile,
              onlyForFriends,
              fsk18,
            }) => ({
              id: imageId,
              isProfile,
              isBillable,
              onlyForFriends,
              locked: unlockedForUser === false,
              price: costs,
              image: { uri: null },
              fsk18,
            })
          )
        : []
    );

export const deleteImage = (id) => webAppClient.delete(`v1/media/delete/${id}`);

export const updateImage = ({ id, isProfile, cost = 0, visible = 'open', is_reported }) =>
  webAppClient.put(
    `v1/media/update/${id}`,
    pickBy(
      {
        is_profile: isProfile,
        cost,
        visible,
        is_reported,
      },
      (item) => item !== undefined
    )
  );

export const getFriendProducts = () =>
  webAppClient
    .get('v1/services/list?key=send_friend_request')
    .then(({ data: { items } }) =>
      items.map(({ key, coinCosts, pieces }) => ({ id: key, price: coinCosts, count: pieces }))
    );

export const buyInternalService = (id) => webAppClient.post(`v1/buy/internal/service/${id}`);

export const ReportUser = ({
  blocked,
  fake_profile,
  photo,
  ad,
  wrong_gender,
  freetext,
  reported_guid,
  violation,
  guideline,
}) =>
  webAppClient
    .post(
      'v1/report/store',
      pickBy(
        {
          blocked,
          fake_profile,
          photo,
          ad,
          wrong_gender,
          freetext,
          reported_guid,
          violation,
          guideline,
        },
        (item) => item !== undefined
      )
    )
    .then(({ status, data: { items } }) => (status === 200 ? items : null));

export const MarkAsRead = (hashId) => webAppClient.get(`v1/conversations/markasread/${hashId}`);

const flattenConfig = (config) => {
  const { children, value } = config;
  if (children !== undefined) {
    return mapValues(children, (val) => flattenConfig(val));
  }
  if (value !== undefined) {
    return value;
  }
  return config;
};

export const GetConfig = () =>
  webAppClient
    .get('v1/configurations')
    .then(({ data: { items: { app } } }) => flattenConfig(app))
    .then(
      ({
        payments,
        app_name: appName,
        messages: { max_chars: maxCharacters, cost_to_chars: messageCost },
        statements: { active },
        taxes,
      }) => ({
        appName,
        payments: mapValues(
          payments,
          ({ title, is_active: isActive, processor, weight: order }) => ({
            type: title,
            isActive,
            processor,
            order,
          })
        ),
        taxes,
        messaging: {
          maxCharacters,
          messageCost: toPairs(messageCost).map(([limit, price]) => ({
            limit: parseInt(limit, 10),
            price,
          })),
        },
        statements: active,
      })
    );

export const SendVerifyEmail = () => webAppClient.get('/v1/auth/resendverifymail');

export const ChangeUserEmail = (newEmail) => webAppClient.put('/v1/users', { email: newEmail });

export const RequestNewPassword = (email) =>
  webAppClient.get('auth/mail/password/reset', { params: { email } });

export const ChangeToNewPassword = (email, password, code) =>
  webAppClient.post('auth/mail/password/reset', { email, password, verify_code: code });

export const getUserFeed = (includes) =>
  webAppClient
    .get('v2/user/feed', {
      params: {
        includes: includes.join(','),
      },
    })
    .then(
      ({
        data: {
          items: {
            newfriends: newFriends,
            newgifts: newGifts,
            newvisitors: newVisitors,
            swipes,
            coins,
            mailverified: isMailVerified,
            freecredits: gotFreeCredits,
            countfriends: friends,
            countpresents: presents,
            friendrequests: friendRequests,
            favoriteLeft,
            activeAbo: hasActiveSubscription,
            mediaReports,
            canChangeLocation,
          },
        },
      }) => ({
        newFriends,
        newGifts,
        newVisitors,
        swipes,
        isMailVerified: isMailVerified !== undefined ? !!isMailVerified : undefined,
        gotFreeCredits: gotFreeCredits !== undefined ? !!gotFreeCredits : undefined,
        friends,
        presents,
        friendRequests,
        coins,
        favoriteLeft,
        hasActiveSubscription,
        hasNoProfilePicture: mediaReports ? !!mediaReports.hasNoProfilePicture : undefined,
        imageDeleted: mediaReports ? !!mediaReports.messages : undefined,
        canChangeLocation,
      })
    );

export const DeleteUser = (reason, message) =>
  webAppClient.delete('/v1/users', { params: { reasonCode: reason, message } });

export const SendSupportMessage = (email, name, subject, message, guid) =>
  webAppClient.post('/v1/support', { email, name, subject, message, guid });

export const VerifyMail = (hash) =>
  webAppClient
    .get(`/auth/mail/verify/${hash}`)
    .then(({ data: { statusCode, items: { accessToken: bearerToken } } }) => ({
      statusCode,
      accessToken: bearerToken,
    }));

export const VerifyMailWithGuid = ({ hash, guid }) =>
  webAppClient
    .get(`/auth/mail/verify/${hash}/${guid}`)
    .then(({ data: { statusCode, items: { accessToken: bearerToken } } }) => ({
      statusCode,
      accessToken: bearerToken,
    }));

export const GetVisited = (nextCursor = null, perPage = 18) =>
  webAppClient
    .get('v1/visits/own', { params: { next_cursor: nextCursor, per_page: perPage, sort: 'desc' } })
    .then(
      ({
        data: {
          items,
          meta: {
            pagination: { has_more_pages: moreItems, next_cursor },
          },
        },
      }) => ({
        visited: items.map(
          ({
            guid: id,
            unlocked,
            userGuid: userId,
            name,
            age,
            picture,
            created_at: createdAt,
          }) => ({
            id,
            userId,
            unlocked,
            name,
            age,
            createdAt,
            image: { uri: picture },
          })
        ),
        moreItems,
        nextCursor: next_cursor,
      })
    );

export const GetLikes = (nextCursorr = null, perPage = 18) =>
  webAppClient
    .get('v3/voting/likes', {
      params: { next_cursor: nextCursorr, per_page: perPage, sort: 'desc', only: 'like' },
    })
    .then(({ data: { items, meta: { pagination: { has_more_pages, next_cursor } } } }) => ({
      likes: items.map(
        ({
          guid: likeId,
          unlocked,
          userGuid: id,
          name,
          age,
          picture,
          city,
          created_at: createdAt,
        }) => ({
          likeId,
          id,
          unlocked,
          name,
          age,
          city,
          createdAt,
          image: { uri: picture },
        })
      ),
      moreItems: has_more_pages,
      nextCursor: next_cursor,
    }));

export const UnlockLike = (id) =>
  webAppClient
    .get(`v1/voting/unlock/${id}`)
    .then(
      ({
        status,
        data: {
          items: {
            userGuid: id,
            name,
            age,
            picture,
            created_at: createdAt,
            city,
            unlocked,
            guid: likeId,
          },
        },
      }) =>
        status === 200
          ? {
              likeId,
              id,
              unlocked,
              name,
              age,
              city,
              createdAt,
              image: { uri: picture },
            }
          : null
    );

export const GetStarPackages = () =>
  webAppClient
    .get('v1/services/list?key=set_favorites')
    .then(({ data: { items } }) =>
      items.map(({ key, coinCosts, pieces }) => ({ id: key, price: coinCosts, count: pieces }))
    );

export const BuyStarPackage = () =>
  webAppClient
    .get('v1/services/list?key=stars')
    .then(({ data: { items } }) =>
      items.map(({ key, coinCosts, pieces }) => ({ id: key, price: coinCosts, count: pieces }))
    );

export const SetFavorite = (userId, favorite) =>
  favorite
    ? webAppClient.get(`v1/favorite/${userId}`)
    : webAppClient.delete(`v1/favorite/${userId}`);

export const RedeemBonusCode = (code) =>
  webAppClient.post('v1/products/gift', { code }).then(
    ({
      data: {
        items: { coins },
      },
    }) => coins
  );

export const LeadLogin = (guid, secret) =>
  webAppClient
    .get(`v1/affiliate/lead/login?guid=${guid}&secret=${secret}`)
    .then(({ data: { statusCode, items: { accessToken: bearerToken, user } } }) => ({
      statusCode,
      accessToken: bearerToken,
      ...transformUserResponse(user),
    }));

export const LoginBySecret = (guid, secret) =>
  webAppClient
    .get(`auth/secret?guid=${guid}&secret=${secret}`)
    .then(({ data: { statusCode, items: { accessToken: bearerToken, user } } }) => ({
      statusCode,
      accessToken: bearerToken,
      ...transformUserResponse(user),
    }));

export const DeleteLoginSecret = () => webAppClient.delete('v1/user/secret');

export const UpdateLeadProfile = ({ gender, birthday, latitude, longitude, city, country }) =>
  webAppClient.put(
    'v1/users',
    pickBy(
      {
        gender,
        birthday,
        lat: latitude,
        lng: longitude,
        city,
        country,
      },
      (item) => item !== undefined
    )
  );

export const UpdateLeadStatus = () => webAppClient.put('v1/affiliate/lead/update?status=active');

export const GetBlockedUsers = (nextCursor = null, perPage = 12) =>
  webAppClient
    .get('v1/report/list', { params: { next_cursor: nextCursor, per_page: perPage } })
    .then(
      ({
        data: {
          items: { users },
          meta: {
            pagination: { has_more_pages: more, next_cursor },
          },
        },
      }) => ({
        users: users.map(({ guid: id, firstname, age, picture, city }) => ({
          id,
          name: firstname,
          age,
          city,
          image: { uri: picture },
        })),
        moreItems: more,
        nextCursor: next_cursor,
      })
    );

export const UnBlockUser = (id) => webAppClient.get(`v1/report/unblock/${id}`);

export const SetStatement = (statement) => webAppClient.put('/v1/users/statement', { statement });

export const SendErrorLog = (error) =>
  webAppClient.post('app/debug', { subject: 'WebApp Error', body: error });

export const UpdatePushSettings = ({
  allowPushNewMessage,
  allowPushNewMatch,
  allowPushNewLike,
  allowPushNewVisitor,
  allowPushNewFriendsrequest,
}) =>
  webAppClient.put(
    'v1/users',
    mapValues(
      pickBy(
        {
          allowPushNewMessage,
          allowPushNewMatch,
          allowPushNewLike,
          allowPushNewVisitor,
          allowPushNewFriendsrequest,
        },
        (a) => a !== undefined
      ),
      (value) => (value ? 1 : 0)
    )
  );

export const GetLocationPredictions = (value, countryCode = 'de') =>
  webAppClient
    .get('/v1/prediction', { params: { city: value, country: countryCode } })
    .then(({ data: { items } }) => items);

export const GetAllowedCountries = () =>
  webAppClient.get('/v1/prediction/allowed_countries').then(
    ({ data: { items } }) =>
      items?.map(({ iso2, flags: { flag48x36 }, name }) => ({
        iso: `${iso2}`.toLowerCase(),
        uri: flag48x36,
        name,
      })) || []
  );

export const IsServerOnline = () =>
  webAppClient
    .get('204')
    .then(() => {
      return true;
    })
    .catch((err) => {
      if (err?.response?.status === 900) return false;
      return true;
    });

export default webAppClient;
