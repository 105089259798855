import axios from 'axios';
import config from '../config';

const placesClient = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/places/',
  responseType: 'jsonp',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

placesClient.interceptors.response.use(
  response => {
    /*
    if (process.env.NODE_ENV !== 'production') {
      console.log(response);
    }
    */
    return response;
  },
  ({ response }) => {
    const { data } = response;
    console.warn('Interceptor: Response:', response);
    return Promise.reject(data);
  }
);

export const getAutoComplete = (input, sessionToken) =>
  placesClient
    .get('/autocomplete/json', {
      params: {
        sessiontoken: sessionToken,
        input,
        types: '(cities)',
        key: config.places.key,
        language: 'de-DE',
        components: 'country:de|country:ch|country:at',
      },
    })
    .then(({ data: { predictions } }) =>
      predictions.map(({ description: name, place_id: id }) => ({ name, id }))
    );

export const getPlace = (placeid, sessionToken) =>
  placesClient
    .get('/details/json', {
      params: {
        placeid,
        sessiontoken: sessionToken,
        key: config.places.key,
        fields: 'geometry,address_component',
        language: 'en-US',
      },
    })
    .then(
      ({
        data: {
          result: {
            geometry: {
              location: { lat, lng },
            },
            address_components: addrComp,
          },
        },
      }) => ({
        lat,
        lng,
        city: addrComp.find(({ types }) =>
          types.find(el => el === 'locality' || 'administrative_area_level_3')
        ).long_name,
        country: addrComp.find(({ types }) => types.find(el => el === 'country')).long_name,
      })
    );

export default placesClient;
