import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { RoundedButton, Input } from './ThemedComponents';
import Icon from './Icon';
import StyleGuide from './StyleGuide';
/**
 * Custom Text Input Component with Icon
 */
const InputTextWithIcon = ({
  error = false,
  onChange,
  isPassword,
  value,
  placeholder,
  errorPlaceholder,
  iconName,
  margin,
  className,
  stretch,
  iconSize,
  bgColor,
  inputColor,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  return (
    <RoundedButton
      padding="0px 10px"
      margin={margin || '10px 5px'}
      justifyContent="flex-start"
      noPointer
      backgroundColor={error ? StyleGuide.colors.error : bgColor || theme.input}
      className={className}
    >
      <Icon name={iconName} color={theme.inputIcon} size={iconSize || 25} />
      <Input
        stretch
        type={isPassword ? 'password' : 'text'}
        value={value}
        placeholder={error ? errorPlaceholder : placeholder}
        onChange={onChange}
        margin="0px 10px"
        color={inputColor || theme.inputFont}
        {...props}
      />
    </RoundedButton>
  );
};

export default InputTextWithIcon;
