const themeLight = {
  main: '#E43946',
  chatBubble: 'linear-gradient(180deg, #FF7C3C 0%, #E43946 100%)',
  mainVariantLight: '#66E284',
  mainVariantDark: '#39C45B',
  mainVariantUltraLight: '#66E284',
  backgroundPrimary: '#F2F4F9',
  backgroundSecondary: '#ffffff',
  backgroundFooterLight: 'ffffff',
  backgroundFooterDark: '#696969',
  fontOnPrimary: '#000',
  fontOnPrimaryLight: '#000',
  fontOnSecondary: '#fff',
  fontOnMain: '#fff',
  background: 'linear-gradient(180deg, #FF7C3C 0%, #E43946 100%)',
  noBackground: 'linear-gradient(180deg, #ffffff 0%, #ffffff 100%)',
  noBackgroundLanding: 'linear-gradient(180deg, #F2F4F9 0%, #F2F4F9 100%)',
  noBackgroundSecondary: 'linear-gradient(180deg, #fff 0%, #fff 100%)',
  marker: '#D82D2D',
  markerText: '#fff',
  input: '#fff',
  inputFont: '#000',
  inputIcon: '#E43946',
  inputDark: '#EFF3F4',
  icon: '#E43946',
  loaderLight: '#FFFFFF',
  loaderDark: '#F5F5F5',
  splitter: '#e6e6e6',
  chatInfo: '#D1E7F9',
  chatInfoText: '#000',
  tileFontPrimary: '#E43946',
  tileFontSecondary: '#363738',
  blockOverlay: '#EFF3F4',
  blockOverlayFont: '#000',
  paymentOption: 'linear-gradient(180deg, #ffffff 0%, #ffffff 100%)',
  externalLink: '#E43946',

  payment2022_singleProductBorderColor: '#959BA8',
  payment2022_markedProductBackgroundColor: '#2D3B5B',
  payment2022_normalFontColor: '#000000',
  payment2022_markedFontColor: '#ffffff',
  payment2022_badgeFontColor: '#ffffff',
};

const themeDark = {
  main: '#E43946',
  chatBubble: 'linear-gradient(180deg, #FF7C3C 0%, #E43946 100%)',
  mainVariantLight: '#66E284',
  mainVariantDark: '#39C45B',
  mainVariantUltraLight: '#66E284',
  backgroundPrimary: '#2E2F30',
  backgroundSecondary: '#363738',
  backgroundFooterLight: '#363738',
  backgroundFooterDark: '#2E2F30',
  fontOnPrimary: '#fff',
  fontOnPrimaryLight: '#fff',
  fontOnSecondary: '#fff',
  fontOnMain: '#fff',
  background: 'linear-gradient(180deg, #FF7C3C 0%, #E43946 100%)',
  noBackground: 'linear-gradient(180deg, #2E2F30 0%, #2E2F30 100%)',
  noBackgroundLanding: 'linear-gradient(180deg, #2E2F30 0%, #2E2F30 100%)',
  noBackgroundSecondary: 'linear-gradient(180deg, #363738 0%, #363738 100%)',
  marker: '#D82D2D',
  markerText: '#fff',
  input: '#4C4D4F',
  inputFont: '#fff',
  inputIcon: '#E43946',
  inputDark: '#4C4D4F',
  icon: '#E43946',
  loaderLight: '#4C4D4F',
  loaderDark: '#363738',
  splitter: '#4C4D4F',
  chatInfo: '#4C4D4F',
  chatInfoText: '#fff',
  tileFontPrimary: '#fff',
  tileFontSecondary: '#D3D3D3',
  blockOverlay: '#4C4D4F',
  blockOverlayFont: '#fff',
  paymentOption: 'linear-gradient(180deg, #363738 0%, #363738 100%)',
  externalLink: '#E43946',
};

export default { themeLight, themeDark };
