const Config = {
  dev: true,
  api: {
    url:
      process.env.NODE_ENV === 'production'
        ? 'https://api.lovd.de/api/'
        : 'https://api.lovd.de/api/',
  },
  cacheAmount: 10,
  urls: {
    web: 'https://belovd.app',
    imprint: 'https://belovd.app/impressum/',
    privacy: 'https://belovd.app/datenschutz/',
    tos: 'https://belovd.app/agb',
    faq: 'https://belovd.app/faq',
    guidelines: 'https://belovd.app/richtlinien/',
    googlePlaystore: '',
    // 'https://bqyxyd.onelink.me/KSam?pid=Store%20Badges%20Web&af_web_dp=https%3A%2F%2Fherzlich.app&c=store_badges_web&af_dp=herzlich%3A%2F%2F',
    appleStore: '',
    // 'https://bqyxyd.onelink.me/KSam?pid=Store%20Badges%20Web&af_web_dp=https%3A%2F%2Fherzlich.app&c=store_badges_web&af_dp=herzlich%3A%2F%2F',
  },
  places: {
    key: 'AIzaSyCxrdY8oMcVzPsp_XHgXobkUFzqbcQ1y2U',
  },
  maxImagePrice: 10000,
  s3: {
    dirName: 'app/tickets/media_upload',
    bucketName:
      process.env.NODE_ENV === 'production'
        ? 'media.icatchedfiles.de'
        : 'mediadev.icatchedfiles.de',
    region: 'eu-central-1',
    accessKeyId: 'AKIA257POBS7WP7C2A74',
    secretAccessKey: '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',
    s3Url:
      process.env.NODE_ENV === 'production'
        ? 'https://s3.eu-central-1.amazonaws.com/media.icatchedfiles.de/'
        : 'https://s3.eu-central-1.amazonaws.com/mediadev.icatchedfiles.de/',
  },
  versionNumber: 'Version 1.0 Build 13',
  appName: 'BELOVD',
  appCurrency: 'Points',
  appCurrencySymbol: 'P',
  acceptedTypes: ['image/jpg', 'image/jpeg', 'image/png'],
  appsFlyer: {
    webKey: '5122261a-f309-41d3-932c-023e67745bb7',
  },
  fbAppID: '816693795818522',
  googleClientID: '877267622651-sdfbv80pohcr2pmd7ns7bbhlo0eam868.apps.googleusercontent.com',
  availableCountries: ['Germany', 'Switzerland', 'Austria'],
  recaptcha: {
    webKey: '6LcdQG4aAAAAAGonK3e1Y39ABpdvf901E1OFpN4q',
    secretKey: '6LcdQG4aAAAAAHo0IO5UORB1i03Hl8ytkHla0uNX',
  },
};

export default Config;
