import React, { useCallback, useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Config from '../config';
import {
  Text,
  CenterDiv,
  Image,
} from './ThemedComponents';
import Icon from './Icon';
import StyleGuide from './StyleGuide';
import { withTranslation } from '../i18n';
import { useMediaQuery } from '../hooks';

const DivContainer = styled.div`
  width: ${props => props.size || '250px'};
  height: ${props => props.size || '250px'};
  border-radius: ${StyleGuide.borders.roundedButton};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  overflow: hidden;
  ${StyleGuide.boxShadowHigh};
  margin: ${({ margin }) => margin || 'auto'};
  background: ${({ background, theme: { input } }) => background || input};
`;

/**
 * Image Upload Component with Draggable feature 
 */
const InputImage = ({
  margin,
  text,
  checked,
  value,
  onChange,
  background,
  setImageError,
  t,
  size,
  error = false,
  setPreviewPath,
  ...props
}) => {
  const [preview, setPreview] = useState('');
  const isSmall = useMediaQuery(['(max-width: 414px)']);
  const theme = useContext(ThemeContext);
  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();

    if (!Config.acceptedTypes.includes(acceptedFiles[0]?.type)) {
      setImageError(true);
      return;
    }

    reader.onload = function (e) {
      onChange(acceptedFiles[0]);
      setPreview(e.target.result);
    };

    reader.readAsDataURL(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  useEffect(() => {
    if (setPreviewPath)
      setPreviewPath(preview);
  }, [preview]);

  return (
    <DivContainer {...getRootProps()} background={background} size={size} margin={margin}>
      <input {...getInputProps()} />
      {value && (
        <Image src={preview} style={{ objectFit: 'cover' }} width={size || '250px'} height={size || '250px'} />
      )}
      {isDragActive && !value && (
        <Text color={theme.inputFont} textAlign="center">
          {t('dragHere')}
        </Text>
      )}
      {!isDragActive && !value && (
      <CenterDiv background={background || theme.input}>
        <Icon name="box-profile" size={70} color={theme.inputIcon} />
        <Text color={theme.inputFont} textAlign="center">
          {isSmall ? t('clickHere') : t('clickOrDrag')}
        </Text>
      </CenterDiv>
)}
    </DivContainer>
  );
};

export default withTranslation('login')(InputImage);
